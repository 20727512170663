@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;900&display=swap');

body {
  background: url(../src/assets/background.png)no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  font-family: 'Poppins', sans-serif;
}

.bg-blur {
  position: absolute;
  z-index: -1;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

button {
  height: 56px;
  width: 234.33px;
  position: absolute;
  box-shadow: 0px 0px 20px #425b6e;
  background-color: transparent;
  overflow: hidden;
  margin-left: 110px;
}

button::before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

button::after {
  content: "";
  height: 500px;
  width: 500px;
  position: absolute;
  z-index: -1;
  background-image: url(./assets/button-bg.png);
}

button:hover::after {
  animation: spin 0.55s linear forwards;
}

@keyframes spin {
  100% {
    transform: rotate(180deg);
  }
}

.active {
  color: #fff;
  padding: 1rem;
  border-radius: 100%;
}

a:hover {
  background: linear-gradient(40deg, #0C1521 30%, #6F99BC);
  box-shadow: 0px 0px 20px #3e5467;
  color: #fff;
  border-radius: 100%;
}

.icon>svg,
.icon>path {
  width: 30px;
  height: 30px;
}

.icon {
  padding: 4px;
  color: #fff;
}

@media screen and (min-width: 960px) and (max-width: 1300px) {
  h1.font-size {
    font-size: 50px;
  }

  span.font-size {
    font-size: 40px;
  }

  .line {
    line-height: 30px;
  }
}

@media screen and (max-width :960px) {
  button {
    left: 0;
    right: 0;
    margin: auto;
    margin-top: 30px;
  }
}

@layer components {
  body {
    @apply font-secondary text-lg leading-8 text-white;
  }

  .h2 {
    @apply font-primary text-[32px] mb-6 tracking-[10%] uppercase;
  }

  .h3 {
    @apply font-primary font-semibold text-[26px] mb-6 leading-[46px];
  }

  .btn {
    @apply rounded-full font-primary text-white font-medium;
  }

  .btn-sm {
    @apply h-[48px] px-6 text-sm;
  }

  .btn-lg {
    @apply h-[56px] px-10 text-base;
    font-size: 24px;
  }

  .btn-link {
    @apply text-base;
  }

  .text-gradient {
    @apply bg-gradient-to-r text-transparent bg-clip-text from-[#42A6E3] to-[#FF56F6] font-bold font-primary hover: from-[#FF56F6] hover:to-[#42A6E3];
  }

  .section {
    @apply py-8 lg:py-24 lg:h-screen flex items-center;
  }
}